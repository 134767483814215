import React from 'react';
import * as styles from '../../components/index.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import updated from '../../images/stayupdated.png';
import { TwitterTweetEmbed, TwitterTimelineEmbed } from 'react-twitter-embed'

class StayUpdated extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  render(){
    return (
      <div className={styles.stayUpdated}>
        <text className={styles.featureHeader}>Stay Updated on Twitter</text>
        <div className={styles.stayUpdatedImg}>
          <TwitterTimelineEmbed
            sourceType="profile"
            // The preferred screen name goes next: 
            screenName="PropMe_LLC"
            // Style options goes here:
            options={styles.stayUpdatedImg}
          />
        </div>
      </div>
    )
  }
}

export default StayUpdated;