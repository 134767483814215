// extracted by mini-css-extract-plugin
export var landingsection = "index-module--landingsection--1OtIr";
export var landingheading = "index-module--landingheading--138_N";
export var landingheading1 = "index-module--landingheading1--1tLkP";
export var landingsubdesc = "index-module--landingsubdesc--8jvvK";
export var downloadbutton = "index-module--downloadbutton--2LfVV";
export var landingsideimg = "index-module--landingsideimg--2_K0B";
export var whypropme = "index-module--whypropme--2vHON";
export var propmeheading = "index-module--propmeheading--2Zepu";
export var propmedesc = "index-module--propmedesc--Dcsi3";
export var propmeimg = "index-module--propmeimg--2LvRc";
export var stayUpdated = "index-module--stayUpdated--2G-mQ";
export var featureHeader = "index-module--featureHeader--3Qu_E";
export var stayUpdatedImg = "index-module--stayUpdatedImg--3F4SF";
export var about = "index-module--about--11jXS";
export var aboutheading = "index-module--aboutheading--1ovex";
export var aboutsubheading = "index-module--aboutsubheading--3pedg";
export var aboutdesc = "index-module--aboutdesc--31qfg";
export var landingsectionRow = "index-module--landingsectionRow--2Eokl";
export var landingsectionCol = "index-module--landingsectionCol--27TMs";
export var col2 = "index-module--col2--Qe0ub";